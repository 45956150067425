<template>
  <div style="min-height: 97vh">
    <div class="row" style="padding-top: 45vh">
      <div class="col-12 text-center">
        <h2 style="color: #d8000c">{{ this.d_text }}</h2>
      </div>
      <div class="col-12 text-center" style="margin-top: 1%">
        <button
          @click="goToCart"
          class="btn btn-light"
          style="font-weight: 700; font-size: 20px"
        >
          Go back to cart
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getcheckout } from '../../lib-core/lib-firebase';

export default {
  name: "cmp-paymentredirect",
  data: () => ({
    d_response: null,
    d_text: "",
  }),
  async mounted() {
    // this.$store.commit("md_fire/mt_setLoading", true);
    let d_response = await this.m_getresponse();
    if (!d_response.data) {
      this.$router.push({ name: "home" });
      return;
    }
    let d_paymentStatus = d_response.data.status;
    if (d_response.data.success) {
      this.$store.commit("md_fire/mt_setLoading", true);
      if (d_paymentStatus == "PAID") {
        //clear cart from storage
        //clear cart from store
        await this.$store.commit("md_cart/mt_setCart", []);
        await this.$localforage.setItem("fs_cart", []);

        this.$router.replace({
          name: "checkoutsuccess",
          params: { isValid: true },
        });
      } else if (d_paymentStatus == "CANCELLED") {
        this.$router.replace({
          name: "checkout",
        });
      } else if (d_paymentStatus == "REPAID") {
        this.d_text = "Your order has already been processed!";
      } else if (d_paymentStatus == "FAILED") {
        this.d_text = "Your payment has been denied!";
      }
    } else {
      this.d_text = "Your payment has failed. Please try again later.";
    }
    this.$store.commit("md_fire/mt_setLoading", false);
  },
  methods: {
    async m_getresponse() {
      const l_checkoutid = this.$route.query.checkout;
      if (l_checkoutid) {
        const l_checkout = await getcheckout({ checkout: l_checkoutid });
        return l_checkout;
      }
      return false;
    },
    goToCart() {
      this.$store.commit("md_fire/mt_setLoading", true);
      this.$store.commit("md_fire/mt_setDashboardSection", "sessions");
      this.$router.push({ name: "checkout" });
    },
  },
};
</script>

<style>
</style>